import React from "react";
import styled from "styled-components"

const MyMarkerContainer = styled.div`
    position: relative;
    margin: -52px 0 0 -21px;
`
const Tooltip = styled.div`
    position: absolute;
    top: -100%;
    left: 50%;
    display: none;
    min-width: 200px;
    transform: translateX(-50%);
    border-radius: 10px;
    border: 1px solid var(--main-green);
    padding: 15px;
    background: #FFF;
    color #000;
    font-size: 18px;
    line-height: 26px;

    &:hover, &.visible {
        display: block;
    }

    a {
        color: var(--main-green);
        font-weight: bold;
    }
`

const MyMarker = ({ icon, tooltip, $hover }) => {
    return (
        <MyMarkerContainer style={{position: "relative"}}>
            {icon}
            <Tooltip className={$hover ? 'visible' : ''} dangerouslySetInnerHTML={{ __html: tooltip }} />
        </MyMarkerContainer>
        )
};

export default MyMarker;