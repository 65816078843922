import React from "react"
import GoogleMapReact from "google-map-react"
import styled from "styled-components"

import Pointer from "./../../assets/images/svg/map-pin.svg"
import MyMarker from "./MyMarker"

const MapContainer = styled.section`
  width: 100vw;
  height: min(80vw, 500px);
`

const Map = ({ data }) => {

  const center = {
    lat: data.contactMap.latitude,
    lng: data.contactMap.longitude,
  }

  const styles = [
    {
      featureType: "administrative",
      elementType: "all",
      stylers: [
        {
          saturation: "-100",
        },
      ],
    },
    {
      featureType: "administrative.province",
      elementType: "all",
      stylers: [
        {
          visibility: "off",
        },
      ],
    },
    {
      featureType: "landscape",
      elementType: "all",
      stylers: [
        {
          saturation: -100,
        },
        {
          lightness: 65,
        },
        {
          visibility: "on",
        },
      ],
    },
    {
      featureType: "poi",
      elementType: "all",
      stylers: [
        {
          saturation: -100,
        },
        {
          lightness: "50",
        },
        {
          visibility: "simplified",
        },
      ],
    },
    {
      featureType: "road",
      elementType: "all",
      stylers: [
        {
          saturation: "-100",
        },
      ],
    },
    {
      featureType: "road.highway",
      elementType: "all",
      stylers: [
        {
          visibility: "simplified",
        },
      ],
    },
    {
      featureType: "road.arterial",
      elementType: "all",
      stylers: [
        {
          lightness: "30",
        },
      ],
    },
    {
      featureType: "road.local",
      elementType: "all",
      stylers: [
        {
          lightness: "40",
        },
      ],
    },
    {
      featureType: "transit",
      elementType: "all",
      stylers: [
        {
          saturation: -100,
        },
        {
          visibility: "simplified",
        },
      ],
    },
    {
      featureType: "water",
      elementType: "geometry",
      stylers: [
        {
          hue: "#ffff00",
        },
        {
          lightness: -25,
        },
        {
          saturation: -97,
        },
      ],
    },
    {
      featureType: "water",
      elementType: "labels",
      stylers: [
        {
          lightness: -25,
        },
        {
          saturation: -100,
        },
      ],
    },
  ]

  return (
    <MapContainer id="map">
      <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyClXds0uH9Pdp-qs-KYVtAUDeC80fxEGQk" }}
        defaultCenter={center}
        defaultZoom={13}
        options={{ styles: styles }}
      >
        <MyMarker
          lat={center.lat}
          lng={center.lng}
          icon={<Pointer/>}
          tooltip={'<a href="https://bravenew.agency">Brave New</a></br> ul. Berka Joselewicza 14/8,</br>Krakow, Poland'}
        />
      </GoogleMapReact>
    </MapContainer>
  )
}

export { Map }
